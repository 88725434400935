import { Banner, Box, Text } from "@shopify/polaris";
import { useCallback, useState } from "react";

const BannerComponent = ({
  show,
  onDismiss,
  title = "",
  message = "",
  padding,
  hideIcon = false,
  tone = "warning",
  icon = <></>,
}) => {
  if (!show) return null;

  return (
    <Box paddingBlockEnd={padding}>
      <Banner
        tone={tone}
        title={title}
        onDismiss={onDismiss}
        hideIcon={hideIcon}
        icon={icon}
      >
        {message && <Text>{message}</Text>}
      </Banner>
    </Box>
  );
};

export const useBanner = ({
  title = "",
  message = "",
  noMargin = false,
  hideIcon = false,
  icon = <></>,
  tone = "warning",
  onDismiss = () => {},
}) => {
  const [show, setShow] = useState(false);

  const handleDismiss = () => {
    setShow(false);
    onDismiss();
  };

  const Banner = useCallback(
    () => (
      <BannerComponent
        show={show}
        onDismiss={handleDismiss}
        title={title}
        message={message}
        padding={noMargin ? "0" : "400"}
        icon={icon}
        hideIcon={hideIcon}
        tone={tone}
      />
    ),
    [show]
  );

  return {
    Banner,
    showBanner: setShow,
  };
};
